import { ChakraProvider } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AdminContextProvider } from "context";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { googleClientId } from "@config";
import "./index.css";
import { AxiosInterceptor } from "@apis";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={googleClientId}>
      <AdminContextProvider>
        <AxiosInterceptor>
          <ChakraProvider>
            <App />
          </ChakraProvider>
        </AxiosInterceptor>
      </AdminContextProvider>
    </GoogleOAuthProvider>
  </BrowserRouter>
);
