export enum EActionType {
  DELETE = "delete",
  LOGOUT = "log out",
  UPDATE = "update",
}

export enum UserStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum EDateTimeFormat {
  FULL_MONTH_YEAR = "MMMM D, YYYY",
  FULL_MONTH_YEAR_WITH_HOUR = "MMMM D, YYYY [at] h a",
  FULL_MONTH_YEAR_WITH_TIME_AM_PM = "MMMM D, YYYY [at] h:mm a",
  TIME_AM_PM = "hh:mm A",
  DATE = "YYYY-MM-DD",
  MONTH_YEAR = "MMMM YYYY",
  FULL_TIME = "HH:mm:ss",
  UTC = "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
  FULL_DAY_MONTH_YEAR_WITH_SHORT_MONTH = "MMM[.] D[,] YYYY",
  FULL_DAY_MONTH_YEAR_WITH_SHORT_MONTH_AND_TIME_AM_PM = "MMM[.] D[,] YYYY [at] h:mma",
  DAY_MONTH_YEAR_WITH_SPLASH = "DD/MM/YYYY",
  DAY_MONTH_YEAR_DATEPICKER = "dd/MM/yyyy",
  MONTH_DAY_YEAR = "MM/DD/YYYY",
  MONTH_DAY_YEAR_DATEPICKER = "MM/dd/yyyy",
  FULL_DAY_WITH_TIME = "YYYY-MM-DD HH:mm",
  FULL_DAY_WITH_TIME_SEC = "YYYY-MM-DD HH:mm:ss.SSS",
  LOCAL_WITH_OFFSET = "YYYY-MM-DDTHH:mm:ss.SSSZ",
}

export enum EOrderByParams {
  EMAIL = "email",
  CREATED_TIME = "createdTime",
}

export enum EOrder {
  ASC = "asc",
  DESC = "desc",
}

export enum EColor {
  Purple50 = "rgba(80 59 244 / 0.5)",
}

export enum EApiUrl {
  USER_URL = "/v1/admin/user",
  AUTH_URL = "/v1/admin/auth",
  SUBSCRIPTION_URL = "/v1/admin/subscription",
  ENDPOINT_SIGN_IN = "/sign-in",
  ENDPOINT_REQUEST_OTP = "/request-otp",
  ENDPOINT_VERIFY_OTP = "/verify-otp",
  ENDPOINT_SIGN_OUT = "/sign-out",
  PARTNER_URL = "/v1/admin/partner",
  CODES_URL = "/v1/admin/promo-code",
  REDEMPTION_URL = "/v1/admin/redemption",
  EMPLOYER_URL = "/v1/admin/employer",
  ELIGIBLE_EMPLOYEE_URL = "/v1/admin/employee",
  ACTION_LOGGING_URL = "v1/admin/action-logging",
}

export enum EUserFields {
  Id = "_id",
  Email = "email",
  Avatar = "avatar",
  FirstName = "firstName",
  LastName = "lastName",
  PhoneNumber = "phoneNumber",
  CreatedTime = "createdTime",
  Status = "status",
}

export const userFieldLabel: { [key: string]: string } = {
  [EUserFields.Email]: "Email",
  [EUserFields.FirstName]: "First Name",
  [EUserFields.LastName]: "Last Name",
  [EUserFields.PhoneNumber]: "Phone Number",
  [EUserFields.CreatedTime]: "Signup Date",
  [EUserFields.Status]: "Status",
};

export enum EToastStatus {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  LOADING = "loading",
}

export enum AUTH_TYPE {
  EMAIL = "email",
  SMS = "sms",
}

export enum ERoleName {
  ADMIN = "admin",
  MEMBER = "member",
  SYSTEM_USER = "system_user",
}

export enum LOGIN_SCREEN {
  REQUEST_OTP = "request-otp",
  VERIFY_OTP = "verify-otp",
}
