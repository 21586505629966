import { apiInstance } from "@apis";
import { PAGE_SIZE } from "@constants";
import { getFilterDateParams } from "@utils";
import { EApiUrl } from "enums";
import {
  IBaseRes,
  IEmployer,
  IFilterDate,
  IPagable,
  IPartnerRes,
  ISortTable,
} from "types";

export const getPartnerListForEmployer = async () => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<IPartnerRes[]>>>(
    EApiUrl.PARTNER_URL,
    { params: { isActive: true } }
  );
  return data?.data;
};

export const getEmployerList = async (
  filterDate?: IFilterDate,
  search?: string,
  page?: number,
  sort?: ISortTable,
  size = PAGE_SIZE
) => {
  const filter = {
    ...getFilterDateParams(filterDate, "minStartDate", "maxStartDate"),
    ...getFilterDateParams(filterDate, "minEndDate", "maxEndDate"),
  };
  const { data } = await apiInstance.get<IBaseRes<IPagable<IEmployer[]>>>(
    EApiUrl.EMPLOYER_URL,
    {
      params: {
        search,
        ...sort,
        ...filter,
        page,
        size,
      },
    }
  );
  return data?.data;
};

export const addNewEmployer = async (employer: Omit<IEmployer, "_id">) => {
  const data = await apiInstance.post(EApiUrl.EMPLOYER_URL, employer);
  return data;
};

export const updateEmployer = async (employer: IEmployer) => {
  const { _id, ...rest } = employer;
  const data = await apiInstance.put(`${EApiUrl.EMPLOYER_URL}/${_id}`, {
    ...rest,
  });
  return data;
};

export const deleteEmployer = async (id: string) => {
  const data = await apiInstance.delete(`${EApiUrl.EMPLOYER_URL}/${id}`);
  return data;
};
