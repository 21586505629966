import { ELocalStorageKey } from "enums";
import { getRefreshTokenService } from "services";

export const getToken = (key: keyof typeof ELocalStorageKey) => {
  try {
    const json = localStorage.getItem(ELocalStorageKey[key]);
    if (!json) return null;
    return JSON.parse(json);
  } catch (error) {
    removeToken(key);
    return null;
  }
};

export const setToken = (key: keyof typeof ELocalStorageKey, token: any) => {
  localStorage.setItem(ELocalStorageKey[key], JSON.stringify(token));
};

export const removeToken = (key: keyof typeof ELocalStorageKey) => {
  localStorage.removeItem(ELocalStorageKey[key]);
};

export const storeAccessTokens = ({
  accessToken,
  refreshToken,
}: {
  [key in ELocalStorageKey]: string;
}) => {
  localStorage.setItem(
    ELocalStorageKey.AccessToken,
    JSON.stringify(accessToken)
  );
  localStorage.setItem(
    ELocalStorageKey.RefreshToken,
    JSON.stringify(refreshToken)
  );
};

export const createRandomUUID = () => {
  const timestamp = Math.floor(Date.now() / 1000).toString(16);
  const randomValue = Array.from({ length: 10 }, () =>
    Math.floor(Math.random() * 16).toString(16)
  ).join("");
  const counter = Array.from({ length: 6 }, () =>
    Math.floor(Math.random() * 16).toString(16)
  ).join("");
  return timestamp + randomValue + counter;
};
