import { createContext, useContext, useEffect, useMemo, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { MdOutlineModeEdit } from "react-icons/md";
import moment from "moment";
import { Tooltip } from "@chakra-ui/tooltip";

import { Card, HeaderTable, Pagination, TableAdmin } from "@components";
import { EDateTimeFormat, EEligibleEmployeesColumns, eligibleEmployeesColumns, EOrder } from "enums";
import { ModalEmployee, Toolbar } from "./components";
import { getEligibleEmployees } from "services/eligible-employee";
import { IEligibleEmployee, IEmployer, IPagable, ISortTable } from "types";
import { AdminContext } from "context";
import { PAGE_SIZE } from "@constants";
import { cellClass, headerClass } from "views/share-class";
import { getEmployerList } from "services/employer";
import { RxExternalLink } from "react-icons/rx";

const columnHelper = createColumnHelper<IEligibleEmployee>();

const columnsExcludeSort: string[] = [
  EEligibleEmployeesColumns.Id,
  EEligibleEmployeesColumns.Code,
];

const getColumns = (actions: {
  onClickEdit: (id: string) => void;
}): ColumnDef<IEligibleEmployee, any>[] => ([
  columnHelper.accessor(EEligibleEmployeesColumns.EmployeeInfo, {
    id: EEligibleEmployeesColumns.EmployeeInfo,
    header: () => (
      <p className={headerClass}>
        {eligibleEmployeesColumns[EEligibleEmployeesColumns.EmployeeInfo].label}
      </p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(EEligibleEmployeesColumns.EmployerName, {
    id: EEligibleEmployeesColumns.EmployerName,
    header: () => (
      <p className={headerClass}>
        {eligibleEmployeesColumns[EEligibleEmployeesColumns.EmployerName].label}
      </p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(EEligibleEmployeesColumns.EmployerNickname, {
    id: EEligibleEmployeesColumns.EmployerNickname,
    header: () => (
      <p className={headerClass}>
        {eligibleEmployeesColumns[EEligibleEmployeesColumns.EmployerNickname].label}
      </p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(EEligibleEmployeesColumns.TPAName, {
    id: EEligibleEmployeesColumns.TPAName,
    header: () => (
      <p className={headerClass}>
        {eligibleEmployeesColumns[EEligibleEmployeesColumns.TPAName].label}
      </p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(EEligibleEmployeesColumns.PartnerName, {
    id: EEligibleEmployeesColumns.PartnerName,
    header: () => (
      <p className={headerClass}>
        {eligibleEmployeesColumns[EEligibleEmployeesColumns.PartnerName].label}
      </p>
    ),
    cell: (info) => <p className={`${cellClass}`}>{info.getValue()}</p>,
  }),
  columnHelper.accessor(EEligibleEmployeesColumns.Code, {
    id: EEligibleEmployeesColumns.Code,
    header: () => (
      <p className={headerClass}>
        {eligibleEmployeesColumns[EEligibleEmployeesColumns.Code].label}
      </p>
    ),
    cell: (info) => (
      <div className={`${cellClass} flex items-center gap-1`}>
        {info.getValue()}
        <a
          className="hover:opacity-70"
          title="Redirect to promo code"
          href={`/admin/codes?search=${info.getValue()}`}
        >
          <RxExternalLink size={18} color="black" />
        </a>
      </div>
    ),
  }),
  columnHelper.accessor(EEligibleEmployeesColumns.CodeFirstSeenDate, {
    id: EEligibleEmployeesColumns.CodeFirstSeenDate,
    header: () => (
      <p className={headerClass}>
        {eligibleEmployeesColumns[EEligibleEmployeesColumns.CodeFirstSeenDate].label}
      </p>
    ),
    cell: (info) => {
      const data = info.getValue();
      return (
        <p className={cellClass}>
          {data ? moment(info.getValue()).format(EDateTimeFormat.MONTH_DAY_YEAR) : ''}
        </p>
      )
    },
  }),
  columnHelper.accessor(EEligibleEmployeesColumns.RedeemedDate, {
    id: EEligibleEmployeesColumns.RedeemedDate,
    header: () => (
      <p className={headerClass}>
        {eligibleEmployeesColumns[EEligibleEmployeesColumns.RedeemedDate].label}
      </p>
    ),
    cell: (info) => {
      const data = info.getValue();
      return (
        <p className={cellClass}>
          {data ? moment(info.getValue()).format(EDateTimeFormat.MONTH_DAY_YEAR) : ''}
        </p>
      )
    },
  }),
  columnHelper.accessor(EEligibleEmployeesColumns.TermedDate, {
    id: EEligibleEmployeesColumns.TermedDate,
    header: () => (
      <p className={headerClass}>
        {eligibleEmployeesColumns[EEligibleEmployeesColumns.TermedDate].label}
      </p>
    ),
    cell: (info) => {
      const data = info.getValue();
      return (
        <p className={cellClass}>
          {data ? moment(info.getValue()).format(EDateTimeFormat.MONTH_DAY_YEAR) : ''}
        </p>
      )
    },
  }),
  columnHelper.accessor(EEligibleEmployeesColumns.CreatedTime, {
    id: EEligibleEmployeesColumns.CreatedTime,
    header: () => (
      <p className={headerClass}>
        {eligibleEmployeesColumns[EEligibleEmployeesColumns.CreatedTime].label}
      </p>
    ),
    cell: (info) => {
      const data = info.getValue();
      return (
        <p className={cellClass}>
          {data ? moment(info.getValue()).format(EDateTimeFormat.MONTH_DAY_YEAR) : ''}
        </p>
      )
    },
  }),
]);

const EligibleEmployeesContext = createContext<{
  employers: IEmployer[];
  handleForceUpdateList: () => void;
} | undefined>(undefined);

export const EligibleEmployees = () => {
  const navigate = useNavigate();

  const {
    filterDate,
    setIsFetching,
    isFetching,
  } = useContext(AdminContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [sortTable, setSortTable] = useState<ISortTable>({
    orderBy: "createdTime",
    order: EOrder.DESC,
  });
  const [employeesPage, setEmployeesPage] = useState<IPagable<IEligibleEmployee[]>>();
  const [employers, setEmployers] = useState<IEmployer[]>([]);
  const [forceUpdateList, setForceUpdateList] = useState<number>(null);
  const [showModal, setShowModal] = useState(false);
  const [employeeSelected, setEmployeeSelected] = useState<IEligibleEmployee>();

  const employerId = useMemo(() => searchParams.get('employerId'), [searchParams]);
  const search = useMemo(() => searchParams.get('search'), [searchParams]);
  const page = useMemo(() => searchParams.get('page'), [searchParams]);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!page) {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        page: '1',
      });
    }
    (async () => {
      try {
        const res = await getEmployerList(undefined, undefined, 1, undefined, 1000);
        setEmployers(res.result);
      } catch (error) {
        //
      }
    })();
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    (async () => {
      try {
        if (page && isNaN(parseInt(page))) return navigate(`/admin/eligible-employees`);
        setIsFetching(true);
        const res = await getEligibleEmployees({
          page: +page || 1,
          employerId,
          search,
          startDate: filterDate.startDate,
          endDate: filterDate.endDate,
          order: sortTable.order,
          orderBy: sortTable.orderBy,
          size: PAGE_SIZE
        });
        if (!res) return;
        setEmployeesPage(res.data);
      } catch (error) {
        // 
      } finally {
        setIsFetching(false);
      }
    })();
  }, [employerId, page, search, filterDate.startDate, filterDate.endDate, sortTable.order, sortTable.orderBy, forceUpdateList]);

  const handleSort = (sort: ISortTable) => {
    if (columnsExcludeSort.includes(sort.orderBy)) return;
    if (!isFetching) {
      setSortTable(sort);
    }
  };

  const handleForceUpdateList = () => {
    setForceUpdateList(Math.random());
  }

  const onClickEdit = (id: string) => {
    setShowModal(true);
    setEmployeeSelected(employeesPage.result.find(i => i._id === id));
  };

  const handleCloseModal = (isReloadList: boolean) => {
    setEmployeeSelected(undefined);
    setShowModal(false);
    if (isReloadList) {
      handleForceUpdateList();
    }
  };

  return (
    <EligibleEmployeesContext.Provider value={{
      employers,
      handleForceUpdateList,
    }}>
      <Card extra="w-full p-4 card-user-list">
        <HeaderTable
          title="Eligible Employees List"
          addonAfter={<Toolbar onClickAdd={() => { setShowModal(true); }} />}
        />
        <div className="flex w-full flex-1 flex-col justify-between gap-4 overflow-y-hidden">
          <TableAdmin<IEligibleEmployee>
            listTable={employeesPage?.result ?? []}
            sortTable={sortTable}
            setSortTable={handleSort}
            columns={getColumns({ onClickEdit })}
            columnsConfig={eligibleEmployeesColumns}
            onClickRow={(e)=> onClickEdit(e._id)}
          />
          <Pagination<IEligibleEmployee> pageUsers={employeesPage} pageParam={page} />
        </div>
      </Card>
      <ModalEmployee
        employers={employers}
        open={showModal}
        onClose={handleCloseModal}
        id={employeeSelected?._id}
      />
    </EligibleEmployeesContext.Provider>
  );
};

export const useEligibleEmployeesContext = () => {
  const context = useContext(EligibleEmployeesContext);

  if (!context) {
    throw new Error('useEligibleEmployeesContext only using within EligibleEmployeesContext Provider');
  }

  return context;
};
