import { apiInstance } from "@apis";
import { PAGE_SIZE } from "@constants";
import { getFilterDateParams } from "@utils";
import { EApiUrl } from "enums";
import {
  IBaseRes,
  IFilterDate,
  IPagable,
  IPartnerRes,
  ISortTable
} from "types";

const size = PAGE_SIZE;
export const getPartnerList = async (
  filterDate: IFilterDate,
  search: string,
  page: number,
  sort?: ISortTable
) => {
  const filter = getFilterDateParams(filterDate, 'startDate', 'endDate');
  const { data } = await apiInstance.get<IBaseRes<IPagable<IPartnerRes[]>>>(
    EApiUrl.PARTNER_URL,
    {
      params: {
        search,
        ...sort,
        ...filter,
        page,
        size,
      },
    }
  );
  return data?.data;
};

export const createPartner = async (partner: IPartnerRes) => {
  const { data } = await apiInstance.post(EApiUrl.PARTNER_URL, { ...partner });
  return data;
};

export const updatePartner = async (partner: IPartnerRes) => {
  const { _id, ...rest } = partner;
  const { data } = await apiInstance.put(`${EApiUrl.PARTNER_URL}/${_id}`, {
    ...rest,
  });
  return data;
};

export const deletePartner = async (id: string) => {
  const { data } = await apiInstance.delete(`${EApiUrl.PARTNER_URL}/${id}`);
  return data;
};
