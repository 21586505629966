import { Skeleton } from "@chakra-ui/react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ECodeFields, EEmployers, EOrder, subsField } from "enums";
import { useContext, useEffect, useState } from "react";
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from "react-icons/io";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ICodeRes, IColumnType, IPartnerRes, ITablePartner } from "types";
import { CodeModal } from "views";
import { AdminContext } from "context";
import {
  EPartnerActionType,
  EPartnerFields,
  EPartnerStatus,
  EPartnerStatusdLabel,
  EPartnerStatusdLabelRevert,
} from "enums/partners";
import { StatusDropDown } from "components/StatusDropDown";
import { ModalEditPartner } from "views";
import { EmployerModal } from "views/employers/components";

const rowClass = "border-b-1 border-gray-200 h-[42px] py-2";
const SortingIndicator = (order: EOrder) => {
  return order === EOrder.ASC ? <IoIosArrowRoundUp /> : <IoIosArrowRoundDown />;
};

const columnsExcludeSort: string[] = [
  EEmployers.Id,
  EEmployers.Edit,
];

export const TableAdminPartner = <T,>({
  listTable,
  sortTable,
  setSortTable,
  columns,
  action,
  changeStatus,
  codeAction,
  partnerAction,
  employerAction,
  columnsConfig = subsField
}: ITablePartner<T>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const secondPath = location.pathname.split("/")[2];
  const [searchParams] = useSearchParams();
  const [openModalEditPartner, setOpenModalEditPartner] = useState(false);
  const [fieldsModalEditPartner, setFieldsModalEditPartner] =
    useState<IPartnerRes>();
  const [openModalEditCode, setOpenModalEditCode] = useState(false);
  const [fieldsModalEditCode, setFieldsModalEditCode] = useState<
    ICodeRes | any
  >();
  const [openModalEditEmployer, setOpenModalEditEmployer] = useState(false);
  const [fieldsModalEditEmployer, setFieldsModalEditEmployer] = useState<
    ICodeRes | any
  >();
  const { isFetching } = useContext(AdminContext);
  const table = useReactTable({
    data: listTable,
    columns,
    enableMultiSort: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    table.setPageSize(20);
  }, []);

  const handleClickRow = (row: any) => {
    switch (secondPath) {
      case "users":
        return navigate(`${row._id}?${searchParams.toString()}`);
      case "partners":
        setFieldsModalEditPartner(row);
        setOpenModalEditPartner(true);
        return;
      case "employers":
        setFieldsModalEditEmployer(row);
        setOpenModalEditEmployer(true);
        return;
      default:
        return;
    }
  };

  const handleSortTable = (orderBy: string) => {
    if (columnsExcludeSort.includes(orderBy)) return;
    setSortTable({
      orderBy,
      order: sortTable.order === EOrder.ASC ? EOrder.DESC : EOrder.ASC,
    });
  };

  const blockColumn = (row: any): boolean => {
    return !(
      (row.status === EPartnerStatus.Active && +row.attempts < 1) ||
      !row.partner
    );
  };
  return (
    <div className="container-table mt-4 overflow-x-auto overflow-y-auto xl:overflow-x-hidden">
      <table className="w-full overflow-y-hidden">
        <thead className="sticky top-0 hidden bg-white dark:bg-navy-800 md:table-header-group">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="!border-px !border-gray-400">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  onClick={() => handleSortTable(header.column.columnDef.id)}
                  className={`cursor-pointer border-b-[2px] border-gray-500 px-4 py-3 dark:border-gray-200
                  ${columnsConfig[header.column.columnDef.id]?.class || "md:w-[5%]"
                    }`}
                >
                  <div className="z-10 flex items-center justify-center gap-1">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.columnDef.id === sortTable?.orderBy
                      ? SortingIndicator(sortTable.order)
                      : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {!isFetching ? (
          <tbody className="mobile-table max-h-full overflow-y-auto">
            {listTable.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className={`cursor-pointer border-b-[1px] border-gray-200 hover:bg-gray-100 dark:border-gray-700 dark:hover:bg-gray-800
                  ${blockColumn({
                    status: "",
                    partner: false,
                    ...row.original,
                  })
                      ? "cursor-not-allowed opacity-30"
                      : "none"
                    }
                  `}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      data-label={
                        columnsConfig[cell.column.id]?.label || cell.column.id
                      }
                      key={cell.id}
                      className={`flex break-all px-2 text-center md:px-4 ${rowClass} 
              
                      flex items-center justify-between dark:border-gray-700 md:table-cell md:border-b-0`}
                    >
                      <div
                        className="flex items-center justify-center"
                        onClick={() => {
                          if (
                            cell.getContext().column.id ===
                            EPartnerFields.Action
                          ) {
                            action(EPartnerActionType.Delete, row.original);
                          }
                          if (
                            cell.getContext().column.id ===
                            EPartnerFields.Name ||
                            cell.getContext().column.id ===
                            EPartnerFields.CreatedTime
                          ) {
                            handleClickRow(row.original);
                          }
                          if (
                            cell.getContext().column.id === ECodeFields.Edit &&
                            !blockColumn({
                              status: "",
                              partner: false,
                              ...row.original,
                            })
                          ) {
                            setFieldsModalEditCode(row.original);
                            setOpenModalEditCode(true);
                          }
                          if (cell.getContext().column.id === EEmployers.Edit) {
                            setFieldsModalEditEmployer(row.original);
                            setOpenModalEditEmployer(true);
                          }
                        }}
                      >
                        {cell.getContext().column.id !==
                          EPartnerFields.Status ? (
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        ) : (
                          <>
                            <StatusDropDown
                              header={
                                EPartnerStatusdLabel[String(cell.getValue())]
                              }
                              status={
                                cell.getValue() ===
                                String(EPartnerStatus.Active)
                              }
                              listDropdown={[
                                EPartnerStatusdLabelRevert[
                                String(cell.getValue())
                                ],
                              ]}
                              onchange={(data: object) => {
                                changeStatus(EPartnerActionType.ChangeStatus, {
                                  ...row.original,
                                  ...data,
                                });
                              }}
                            ></StatusDropDown>
                          </>
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className={`${rowClass} px-4 text-center`}
                >
                  No data
                </td>
              </tr>
            )}
          </tbody>
        ) : (
          <TableSkeleton col={columns.length} />
        )}
      </table>
      <ModalEditPartner
        showModal={openModalEditPartner}
        onDecline={() => setOpenModalEditPartner(false)}
        data={fieldsModalEditPartner}
        action={partnerAction}
      />
      <CodeModal
        showModal={openModalEditCode}
        onDecline={() => setOpenModalEditCode(false)}
        data={fieldsModalEditCode}
        action={codeAction}
      />
      <EmployerModal
        showModal={openModalEditEmployer}
        onDecline={() => setOpenModalEditEmployer(false)}
        data={fieldsModalEditEmployer}
        action={employerAction}
      />
    </div>
  );
};

const TableSkeleton = ({ col }: { col: number }) => {
  return (
    <tbody>
      {Array.from({ length: 10 }).map((_, index) => (
        <tr key={index}>
          <td colSpan={col} className={`px-0 ${rowClass}`}>
            <Skeleton height="20px" />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableAdminPartner;
