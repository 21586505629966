import { routes } from "routes";

const getPageName = (path: string) => {
  return routes.find((i) => i.path === path)?.name || "";
};


const formatArrayToDetail = (indexes: number[]) => {
  if (indexes.length > 10) {
    return indexes.slice(0, 10).join(", ") + ", ...";
  }
  return indexes.join(", ");
};

export { getPageName, formatArrayToDetail };
