import { Button, Flex } from "@chakra-ui/react";
import { Card, DateRangePicker, HeaderTable, Pagination, SearchInput, TableAdminPartner } from "@components";
import { AdminContext } from "context";
import { EDateParam, EOrder, EToastStatus } from "enums";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { exportCode, getCodeList } from "services";
import { ICodeRes, IPagable, ISortTable } from "types";
import { CodeModal, ColumnsCodes } from "views";

export const CodeList = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page") || "1";
  const searchParam = searchParams.get("search") || "";
  const [sortTable, setSortTable] = useState<ISortTable>({
    orderBy: "createdTime",
    order: EOrder.DESC,
  });
  const [openModalAddCode, setOpenModalAddCode] = useState(false);
  const [pageCodes, setPageCodes] = useState<IPagable<ICodeRes[]>>();
  const {
    setIsFetching,
    isFetching,
    currentTimezone: { name, timezone },
    filterDate,
    showToast,
  } = useContext(AdminContext);
  const { result: listTable }: { result: ICodeRes[] } = pageCodes || {
    result: [],
  };
  const handleSortUsers = (sort: ISortTable) => {
    if (!isFetching) {
      setSortTable(sort);
    }
  };

  const getCodeData = useCallback(async () => {
    if (isNaN(+pageParam)) return navigate(`/admin/codes`);
    const currentParams = Object.fromEntries(searchParams.entries());
    const res = await getCodeList(
      filterDate,
      decodeURIComponent(searchParam),
      +pageParam,
      sortTable
    );
    setPageCodes(res);
    if (res?.pageCount < +pageParam) {
      setSearchParams({ ...currentParams, page: "1" });
    }
  }, [pageParam, searchParams, filterDate, searchParam, sortTable]);

  useEffect(() => {
    (async () => {
      try {
        setIsFetching(true);
        await getCodeData();
      } catch (err: any) {
        showToast("Error", err?.response?.data.message, EToastStatus.ERROR);
      } finally {
        setIsFetching(false);
      }
    })();
  }, [searchParam, pageParam, sortTable, filterDate]);

  const handleExportCode = async () => {
    try {
      let result = await exportCode();
      if (result.data?.data) {
        const link = document.createElement("a");
        link.href = result.data?.data;
        link.download = "CodeList";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {}
  };

  return (
    <Card extra="w-full p-4 card-user-list">
      <HeaderTable
        title="Code List"
        addonAfter={
          <Flex gap={4} flexWrap="wrap">
            <SearchInput />
            <DateRangePicker
              startParam="minStartDate"
              endParam="maxStartDate"
              placeholder="Select start date"
            />
            <DateRangePicker
              startParam="minEndDate"
              endParam="maxEndDate"
              placeholder="Select end date"
            />
            <Button onClick={() => setOpenModalAddCode(true)}>New code</Button>
            <Button onClick={handleExportCode}>Export</Button>
          </Flex>
        }
      />
      <div className="flex w-full flex-1 flex-col justify-between gap-4 overflow-y-hidden">
        <TableAdminPartner
          listTable={listTable}
          sortTable={sortTable}
          setSortTable={handleSortUsers}
          columns={ColumnsCodes}
          action={null}
          changeStatus={null}
          codeAction={getCodeData}
          partnerAction={null}
        />
        <Pagination<ICodeRes> pageUsers={pageCodes} pageParam={pageParam} />
      </div>
      <CodeModal
        showModal={openModalAddCode}
        onDecline={() => setOpenModalAddCode(false)}
        action={getCodeData}
      />
    </Card>
  );
};
